var ZipStorage = (function() {
    const ZIP_CHANGE_EVENT_NAME = 'app.zipChange';
    const ZIP_READY_EVENT_NAME = 'app.zipReady';
    const ZIP_CODE_LOCAL_STORAGE_NAME = 'userZipCode';
    const STATIC_ZIP = '90210';
    const zipChangeEvent = new Event(ZIP_CHANGE_EVENT_NAME);
    const zipReadyEvent = new Event(ZIP_READY_EVENT_NAME);

    const _getItem = function (key) {
        const _currentItem = localStorage.getItem(key);

        if (_currentItem) {
            return _currentItem;
        } else {
            return undefined;
        }
    };

    const _setItem = function (key, value) {
        localStorage.setItem(key, value);
    };

    const setZip = function(zip) {
        _setItem(ZIP_CODE_LOCAL_STORAGE_NAME, zip);
        document.dispatchEvent(zipChangeEvent);
    };

    const getZip = function() {
        return _getItem(ZIP_CODE_LOCAL_STORAGE_NAME);
    };

    const init = function() {
        if (getZip()) {
            return;
        }

        var zipRequest = new Request ('favicon.ico');

        fetch(zipRequest)
            .then(response => {
                if (response.ok) {
                    let zipValue = '';

                    response.headers.get("Akamai-Edgescape")
                        .split(',')
                        .forEach((v) => {
                            let key = v.split('=')[0].trim();

                            if (key === 'zip') {
                                zipValue = v.split('=')[1].substring(0, 5);
                            }
                        });

                    if (zipValue) {
                        setZip(zipValue);
                    } else {
                        setZip(STATIC_ZIP);
                    }

                } else {
                    setZip(STATIC_ZIP);
                    throw new Error(`ZipStorage.init() error, status = ${response.status}`);
                }
            })
            .finally(() => {
                document.dispatchEvent(zipReadyEvent);
            })
            .catch(error => console.error(error));
    };

    const onZipChange = function(cb) {
        document.addEventListener(ZIP_CHANGE_EVENT_NAME, cb);
    }

    const onZipReady = function(cb) {
        document.addEventListener(ZIP_READY_EVENT_NAME, cb);
    }

    return {
        init: init,
        getZip: getZip,
        setZip: setZip,
        onZipChange: onZipChange,
        onZipReady: onZipReady,
        STATIC_ZIP: STATIC_ZIP,
        ZIP_CHANGE_EVENT_NAME: ZIP_CHANGE_EVENT_NAME,
        ZIP_READY_EVENT_NAME: ZIP_READY_EVENT_NAME,
        ZIP_CODE_LOCAL_STORAGE_NAME: ZIP_CODE_LOCAL_STORAGE_NAME
    }
})();

ZipStorage.init();